import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ExpertBanner.css';

const ExpertBanner = () => {
    return (
        <div className="banner-container  ">
            <div className="banner-content row  ">
                <div className="col-md-6 text-content">
                    <h1 className="banner-title">Revolutionizing Startup Ventures with Cutting-Edge Technologies</h1>
                    <p className="banner-description">
                    In today’s fast-paced digital world, innovation is the key to success. At KodeKlan, we empower startups by integrating cutting-edge technologies that drive growth, efficiency, and scalability. Whether you’re launching a new venture or looking to expand, we provide tailored tech solutions that give your business a competitive edge.
                    </p>
                    <p className="banner-description">
                    From AI-driven applications and cloud-based solutions to blockchain integration and custom software development, we bring the latest advancements to your startup. Our team of experts ensures that your business stays ahead of the curve by leveraging smart automation, data analytics, and scalable IT infrastructure.
                    </p>
                    <p className="banner-description">
                    We don’t just develop technology—we create future-ready solutions that transform ideas into successful businesses. With KodeKlan, your startup gains the power of innovation, ensuring a seamless journey from concept to reality.
                    </p>
                    <p className="banner-description">
                    Partner with us today and take your startup to the next level
                    </p>
                    {/* <button className="talk-button">
                        <span className="button-text">TALK TO OUR EXPERTS</span>
                        <span className="button-icon">→</span>
                        <div className="hover-effect"></div>
                    </button> */}
                </div>
                <div className="col-md-6 image-section">
                    <div className="image-container">
                        <img className='image-container-img businessman-image'
                            src="/img/images/DigitalTransformation.png"
                            // height="100px"
                            alt="DigitalTransformation"
                        />
                        
                        <div className="feature-tags">
                            <div className="feature-tag">
                                <i className="tag-icon">🔒</i>
                                <span>100% NDA-protected contract</span>
                            </div>
                            <div className="feature-tag">
                                <i className="tag-icon">⚙️</i>
                                <span>100% resource substitution*</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpertBanner;