import React, { useState } from "react";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import Layout from "../../layouts/Layout";
import EnquiryForm from "../../components/Careers/EnquiryForm";
import JobLists from "./JobLists";
import { Link } from "react-router-dom";
import { jobListings } from "../../jobs";

const Careers = () => {
    



    return (
        <Layout header={1} footer={1} className="" mainClassName="">
            <BreadcrumbArea
                title="Careers"
                subtitle={"Careers"}
                className={"pt-175 pb-4"}
            />
            <div className="careers">
                <div className="container p-4">
                    <p>
                        Become part of our dynamic team, where innovation, creativity, and excellence thrive in a collaborative environment that supports personal growth and embraces cutting-edge technology.
                    </p>
                    <div className="header-action d-flex justify-content-center align-items-center container">
                        <ul className="list-wrap" style={{ marginLeft: 0 }}>
                            <li className="header-btn">
                                <a href="#openings" className="btn wow fadeInUp">
                                    View Open Positions <span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Interview Process */}
                <div className="interview-process-container container">
                    <h1 className="process-title">Interview Process</h1>
                    <h2 className="process-subtitle">How To Apply</h2>
                    <p className="intro-text">
                        Be Part of a Digital Revolution
                        At KodeKlan, we believe in turning ideas into impactful solutions. Whether you're a tech wizard, a design enthusiast, or a marketing mastermind, there's a place for you here. Together, we create digital innovations that shape industries and transform businesses
                    </p>
                    <div className="steps">
                        <div className="step">
                            <h3>01</h3>
                            <p>
                                <strong>HR Round</strong><br />
                                Kickstart your journey with an initial discussion with our HR team. This round helps us understand your aspirations and introduce you to the job role and expectations.
                            </p>
                        </div>
                        <div className="step">
                            <h3>02</h3>
                            <p>
                                <strong>Technical Assessment</strong><br />
                                Showcase your skills with a brief technical assessment tailored to the requirements of the role. This step helps us gauge your expertise and problem-solving abilities.
                            </p>
                        </div>
                        <div className="step">
                            <h3>03</h3>
                            <p>
                                <strong>Manager's Round</strong><br />
                                Meet your potential manager to discuss your approach, work style, and how you can contribute to the team’s success.
                            </p>
                        </div>
                        <div className="step">
                            <h3>04</h3>
                            <p>
                                <strong>Final HR Discussion</strong><br />
                                Wrap up with a discussion on salary, benefits, and other important details to ensure a smooth onboarding experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Open Roles */}
            <div className="job-openings container text-center">
                <p><span className="current-openings">Current Openings</span></p>
                <h2 className="available-roles">Available Job Roles</h2>
                <div className="job-listings">
                    {jobListings.map((job) => (
                        <div key={job._id} className="job-listing">
                            <JobLists
                                jobTitle={job.jobTitle}
                                company={job.company}
                                location={job.city}
                                description={job.description}
                                jobId={job._id}
                            />
                        </div>
                    ))}
                </div>
            </div>


        </Layout >
    );
};

export default Careers;
