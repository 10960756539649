import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhyUs.css';

const WhyUs = () => {
  return (
    <div className="services-container">
      <div className="container">
        <div className="text-center services-header">
          <h1 className="main-heading">Why Choose KodeKlan for Your Startup App Development Project?</h1>
          <p className="subtitle">
          At KodeKlan, we don’t just build apps—we create powerful, scalable, and future-ready solutions that help startups thrive in the digital landscape. Whether you’re launching a new venture or looking to expand your business, our expert team is here to transform your ideas into a high-performance application. Here’s why startups trust us:
          </p>
        </div>
        
        <div className="row g-4 mt-4">
         
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Startup-Focused Approach <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              We understand the challenges startups face, from budget constraints to market competition. That’s why we offer cost-effective, high-quality solutions designed specifically to help new businesses scale quickly.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>End-to-End Development Solutions <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              From idea validation and UI/UX design to development, testing, and deployment, we provide comprehensive services under one roof. We handle every step, ensuring a seamless app development journey for your startup.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Cutting-Edge Technologies<span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              We leverage the latest technologies such as AI, blockchain, IoT, cloud computing, and advanced analytics to build innovative, future-ready applications that keep you ahead of the competition.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Agile Development & Faster Time-to-Market <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              Speed is crucial for startups, and we ensure rapid development with an agile methodology. Our streamlined process helps you launch your app faster, giving you a competitive edge.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Scalable & Secure Solutions <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              We build applications that are highly scalable and secure, ensuring your startup can handle growth, traffic surges, and evolving business needs without performance issues.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2> Equity-Based Funding Support <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              If lack of funds is holding you back, we offer financial assistance on an equity basis, helping startups bring their ideas to life without upfront capital investment.
              </p>
            </div>
          </div>

          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2> Post-Launch Support & Maintenance <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              Our relationship doesn’t end after deployment. We provide ongoing support, updates, and maintenance, ensuring your app stays optimized, bug-free, and up-to-date with the latest trends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;