import React from "react";
import { Link } from "react-router-dom";

const FooterOne = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()


  return (
    <footer>
      <div className="footer-area position-relative">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="logo">
                    <Link to="/" className="footer-logo-link mb-4">
                    <img className=" mt-30 footer-logo" src="/img/logo/logo_kodeklan.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="footer-social mt-4">
                    <ul className="list-wrap list-none">
                      <li>
                        <a href="https://www.facebook.com/share/spSSDfVvCstAvVS7/?mibextid=qi2Omg">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/invites/contact/?igsh=1ht3x78l61ifb&utm_content=vu9okd4">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/105264915/admin/page-posts/published/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="fw-link">
                    <ul className="list-wrap list-none">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer-contact">
                    <span>For Support</span>
                    <h2 className="title">
                      <a href="tel:0123456789">+91-120 477 4269</a>
                    </h2>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Services</h4>
                  <div className="fw-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/services/mobile-development">Mobile Development</Link>
                      </li>
                      <li>
                        <Link to="/services/software-development">Software Development</Link>
                      </li>
                      <li>
                        <Link to="/services/digital-Transformation">Digital Transformation</Link>
                      </li>
                      <li>
                        <Link to="/services/ideational-designing">ideation and Designing</Link>
                      </li>
                      <li>
                        <Link to="/services/it-consulting">IT consulting</Link>
                      </li>
                      <li>
                        <Link to="/services/devops">Devops</Link>
                      </li>
                      <li>
                        <Link to="/services/cloud-managed-service">Cloud managed Service</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="fw-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/terms-condition">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy & Policy</Link>
                      </li>
                      {/* <li>
                        <Link to="/contact">Clients & Testimonials</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/faq">FAQs</Link>
                      </li> */}
                      <li>
                        <Link to="/careers">Career</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Contact Us</h4>
                  <div className="footer-about">
                    <ul className="list-wrap">
                      <li>
                        <img src="/img/icon/phone_icon.svg" alt="" />
                        <a href="tel:1204774269">+91-120 477 4269</a>
                      </li>
                      <li>
                        <img src="/img/icon/mail_icon.svg" alt="" />
                        <a href="mailto:hello@kodeklan.com">hello@kodeklan.com</a>
                      </li>
                      <li>
                        <img src="/img/icon/loction_icon.svg" alt="" />
                        <span>Suite: B-09, Plot : H-161, BSI Business Park, Sector-63, Noida (U.P) 201301</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container ">
            <div className="text-center">
              <p>© {currentYear} Kode Klan. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
