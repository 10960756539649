 export const jobListings = [
    {
        _id: "1",
        jobTitle: "Full Stack Developer",
        company: "Kodeklan Pvt. Ltd.",
        location: "B-09, H-161, BSI Business Park, Sector–63, Noida, Uttar Pradesh 201301",
        city: "Noida, UP",
        jobType: "Full-Time (onsite)",
        experienceLevel: "0-2 Years",
        description: `We are looking for an enthusiastic and experienced Full Stack Developer to join our development team. 
                    In this role, you will be responsible for developing, implementing, and maintaining web applications 
                    using MongoDB, Express.js, React.js, React Native, and Node.js technologies.`,
        aboutCompany: `At Kodeklan Pvt. Ltd., we specialize in crafting tailored websites and delivering a full suite of IT services 
                     designed to empower businesses in today’s fast-paced digital landscape. We understand the challenges of working 
                     within a budget, and we ensure that innovation doesn’t come at a hefty price. Our team blends creativity, technical 
                     expertise, and strategic insight to help you scale your ideas into impactful solutions.`,
        keyResponsibilities: [
            "Design, develop, and maintain web applications using the MERN stack and React Native.",
            "Write clean, maintainable, and efficient code following best practices.",
            "Design and implement RESTful APIs and web services.",
            "Write and execute unit tests, integration tests, and debugging.",
            "Utilize Git for version control and collaborate with team members.",
            "Troubleshoot and resolve technical issues.",
            "Maintain and update technical documentation for applications."
        ],
        requiredSkills: [
            "Proven experience as a Full Stack Developer or similar role.",
            "Strong proficiency with JavaScript, including ES6+ features.",
            "Experience with MongoDB, Express.js, React.js, React Native, and Node.js.",
            "Familiarity with front-end technologies like HTML, CSS, and JavaScript frameworks.",
            "Experience with RESTful APIs and integrating third-party services.",
            "Version control expertise using Git and GitHub/GitLab.",
            "Familiarity with cloud platforms like AWS is a plus.",
            "Understanding of security and performance issues in web applications.",
            "Strong problem-solving skills and ability to work both independently and in a team.",
            "Strong communication skills and ability to work in an Agile environment."
        ],
        preferredQualifications: [
            "Experience with state management libraries like Redux.",
            "Knowledge of Electron.js for building cross-platform desktop applications."
        ]
    }
];