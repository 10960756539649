import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import WOW from "wow.js";
import CreativeAgency from "./pages/Home/CreativeAgency";
import AboutUs from "./pages/About/AboutUs";
import TeamPage from "./pages/Team/TeamPage";
import TeamPageDetails from "./pages/Team/TeamPageDetails";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
import ServicesDetailsPage from "./pages/ServicesDetailsPage";
import ContactPage from "./pages/ContactPage";
import AnimatedCursor from "react-animated-cursor";
import MobileDevelopment from "./pages/servicePages/mobileDevelopment/MobileDevelopment";
import SoftwareDevelopment from "./pages/servicePages/softwareDevelopment/SoftwareDevelopment";
import Devops from "./pages/servicePages/devops/Devops";
import CloudManagedServices from "./pages/servicePages/CloudManagedServices/CloudManagedServices";
import DigitalTransformation from "./pages/servicePages/DigitalTransformation/DigitalTransformation";
import ItConsulting from "./pages/servicePages/ItConsulting/ItConsulting";
import IdeationandDesigning from "./pages/servicePages/IdeationandDesigning/IdeationandDesigning";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Projects from "./pages/Projects/Projects";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import NotFoundPage from "./pages/NotFoundPage";
import { Helmet } from "react-helmet";
import Careers from "./pages/Careers/Careers";
import ReactPixel from 'react-facebook-pixel'
import BlogPage from './pages/Blog/BlogPage.jsx'
import BlogDetailsPage from './pages/Blog/BlogDetailsPage.jsx'
import JobDescription from "./pages/Careers/JobDescription";
import Blog1 from "./components/Blog/BlogPages/Blog1";
import LandingPage from "./pages/LandingPage/LandingPage.jsx";
// import Blog2 from "./components/Blog/BlogPages/Blog2";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>KodeKlan - Creative Agency</title>
        <meta
          name="description"
          content="KodeKlan is a creative agency with a focus on digital transformation and innovation."
        />
        <meta property="og:title" content="KodeKlan - Creative Agency" />
        <meta
          property="og:description"
          content="KodeKlan is a creative agency with a focus on digital transformation and innovation."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kodeklan.com" />
        <meta
          property="og:image"
          content="https://kodeklan.com/assets/img/logo_kodeklan.png"
        />
        <meta property="og:site_name" content="KodeKlan" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <AnimatedCursor
        innerSize={8}
        outerSize={32}
        color="248, 158, 82"
        outerAlpha={0.15}
        innerScale={0}
        outerScale={0}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />

      <Routes>
        <Route path="/" element={<CreativeAgency />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team-details" element={<TeamPageDetails />} />
        <Route path="/project-details" element={<PortfolioDetailsPage />} />
        <Route path="/services" element={<ServicesDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/startup-development" element={<LandingPage />} />
        <Route path="/blog-details" element={<BlogDetailsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/1" element={<Blog1 />} />
        {/* <Route path="/blogs/2" element={<Blog2 />} /> */}
        <Route path="/job-description/:jobId" element={<JobDescription />} />


        <Route
          path="/services/mobile-development"
          element={<MobileDevelopment />}
        />
        <Route
          path="/services/software-development"
          element={<SoftwareDevelopment />}
        />
        <Route path="/services/devops" element={<Devops />} />
        <Route
          path="/services/cloud-managed-service"
          element={<CloudManagedServices />}
        />
        <Route
          path="/services/digital-Transformation"
          element={<DigitalTransformation />}
        />
        <Route path="/services/it-consulting" element={<ItConsulting />} />
        <Route
          path="/services/ideational-designing"
          element={<IdeationandDesigning />}
        />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
    </>
  );
}

export default App;
