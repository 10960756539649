import emailjs from "@emailjs/browser";


export const sendEmail = (formData, serviceID, templateID, publicKey) => {
    emailjs.init(publicKey)

    return emailjs.sendForm(serviceID, templateID, formData)
        .then((response) => {
            console.log(response)
            return response
        }).catch((error) => {
            console.log(error)
            throw error
        })
}
