import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './StartupsBenefits.css';
import { Link } from 'react-router-dom';

const StartupsBenefits = () => {
  // const [prevEl, setPrevEl] = useState(null);
  // const [nextEl, setNextEl] = useState(null);

  const cards = [
    {
      icon: '🧩',
      title: 'Enhanced Customer Engagement & Experience',
      description: 'An app provides a direct and personalized way to interact with your audience. With features like push notifications, in-app messaging, and seamless navigation, businesses can keep users engaged, boost retention rates, and create a loyal customer base.'
    },
    {
      icon: '🚀',
      title: ' Competitive Edge in the Market',
      description: 'In an era where consumers prefer convenience, having an app sets you apart from competitors who rely only on websites or traditional methods. A well-designed application enhances brand credibility and helps you establish a strong market presence.'
    },
    {
      icon: '🔗',
      title: 'Process Automation & Efficiency',
      description: 'Applications streamline business operations by automating processes like order management, payments, customer support, and more. This reduces manual work, increases efficiency, and allows startups to focus on growth instead of repetitive tasks.'
    },
    {
      icon: '📊',
      title: 'Increased Revenue & Business Growth',
      description: 'A mobile or web app can open up multiple revenue streams, such as in-app purchases, subscription models, or e-commerce sales. With user-friendly interfaces and seamless payment integrations, apps help convert more users into paying customers.'
    },
    {
      icon: '🎯',
      title: 'Data-Driven Decision Making',
      description: 'Apps provide valuable insights through analytics, helping businesses track user behavior, preferences, and trends. Startups can use this data to make informed business decisions, improve services, and enhance user experiences.'
    },
    {
      icon: '📈',
      title: 'Scalability & Future Readiness',
      description: 'Unlike traditional business models, an app allows startups to scale effortlessly. With cloud integration and robust backend support, startups can expand their services, reach a global audience, and adapt to growing customer demands.'
    },
  ];

  return (
    <div className="startup-software-container">
      <div className="startup-software-header">
        <h1>Why Should Startups Consider Developing Applications?</h1>
        <p>
        In today’s digital-driven world, having a mobile or web application is no longer a luxury—it’s a necessity for startups looking to scale and succeed. Whether you’re launching a new business or expanding your services, an application can revolutionize how you connect with customers, automate processes, and generate revenue. Here’s why startups should invest in app development:
        </p>
      </div>

      <div className="startup-software-content">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            }
          }}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index}>
              <div className="card">
                <div className="card-icon">
                  <span>{card.icon}</span>
                </div>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="startup-software-footer">
        <Link to='/contact'>
        <button className="connect-btn">
          <span className="btn-text">LET'S CONNECT</span>
          <span className="btn-icon">↗</span>
          <div className="btn-hover-effect"></div>
        </button>
        </Link>

        {/* <div className="navigation-buttons">
          <button ref={setPrevEl} className="nav-btn prev-btn">
            <span>←</span>
            <div className="btn-hover-effect"></div>
          </button>
          <button ref={setNextEl} className="nav-btn next-btn">
            <span>→</span>
            <div className="btn-hover-effect"></div>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default StartupsBenefits;