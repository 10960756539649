import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function ItCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "100%",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/TechnologyAssessments.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Technology Assessments</h3>
                  <p>
                    We conduct thorough technology assessments to evaluate your
                    current IT landscape. By identifying strengths and
                    weaknesses, we provide actionable insights to enhance
                    performance and operational efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/StrategicITPlanning.png"
                  height="100px"
                  alt=""
                />

                <div className="textcont">
                  <h3>Strategic IT Planning</h3>
                  <p>
                    Our long-term IT strategies align with your business
                    objectives and support growth. We tailor our planning
                    services to your unique needs and industry trends for
                    sustainable success.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/riskmanagement.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Risk Management</h3>
                  <p>
                    Mitigating IT risks is vital for safeguarding your
                    organization. Our strategies identify and assess
                    vulnerabilities, helping you create a robust framework to
                    protect assets and ensure business continuity.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/systemintegration.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>System Integration</h3>
                  <p>
                    We provide system integration solutions for seamless
                    connectivity between disparate technologies. This enhances
                    data flow and collaboration, reducing operational silos and
                    boosting efficiency.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/ITgovernance.png"
                  height="100px"
                  alt=""
                />

                <div className="textcont">
                  <h3>IT Governance</h3>
                  <p>
                    Effective IT governance manages resources and ensures
                    compliance. We help establish frameworks that promote
                    accountability and transparency, supporting strategic
                    alignment in your operations.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/ITConsulting/PartnerwithUsforInnovation.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Partner with Us for Innovation</h3>
                  <p>
                    By partnering with us, you can implement IT strategies that
                    drive innovation and performance. Our consultants are
                    dedicated to understanding your challenges and delivering
                    empowering solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
