import React from "react";
import Layout from "../../layouts/Layout";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import Policies from "./Policies";

function PrivacyPolicy() {
  return (
    <>
      <Layout header={1} footer={1} className="" mainClassName="">
        <BreadcrumbArea
          title={"Privacy Policy"}
          subtitle={"Privacy Policy"}
          showShape={false}
          className={" breadcrumb-area-two pt-175 pb-150"}
        />
        <Policies />
      </Layout>
    </>
  );
}

export default PrivacyPolicy;
