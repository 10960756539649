import React, { useEffect } from "react";
import Layout from "../../layouts/Layout";
import Header from "../../components/LandingPage/Header";
import ExpertBanner from "../../components/LandingPage/ExpertBanner";
import ServiceBanner from "../../components/LandingPage/ServiceBanner";
import StartupBenefits from "../../components/LandingPage/StartupsBenefits";
import WhyUs from "../../components/LandingPage/WhyUs";
import ReactPixel from "react-facebook-pixel";

const LandingPage = () => {
    // useEffect(() => {
    //     // Track page view on Facebook Pixel
    //     ReactPixel.pageView();

    //     // Track page view on Google Analytics
    //     if (typeof window !== "undefined" && window.gtag) {
    //         window.gtag("config", "G-6FN6H58Q3Z", {
    //             page_path: window.location.pathname,
    //         });
    //     }
    // }, []);

    return (
        <Layout header={1} footer={1}>
            <Header />
            <ExpertBanner />
            <ServiceBanner />
            <StartupBenefits />
            <WhyUs />
        </Layout>
    );
};

export default LandingPage;