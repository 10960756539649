import React from 'react';
import { Link } from 'react-router-dom';
import "./JobDescription.css"

const JobLists = ({ jobTitle, company, location, description, jobId }) => {
  return (
    <div className="job-card">
      <div className="job-card-header">
        <h2>{jobTitle}</h2>
        <p className="company-name">{company}</p>
        <p className="location">{location}</p>
      </div>
      <div className="job-card-body">
        <p className="job-description">{description.slice(0, 20)}...</p>
      </div>
      <div className="job-card-footer">
        <Link to={`/job-description/${jobId}`} className="apply-btn">
          View JD
        </Link>
      </div>
    </div>
  );
};

export default JobLists;
