import React from "react";

const NewsLetterArea = () => {
  return (
    <section className="newsletter-area pt-110 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb">
              <span className="sub-title">Get update</span>
              <h1 className="">
                Get latest updates <br />
                and deals at
              </h1>
            </div>
          </div>
        </div>
        
        <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="newsletter-form">
                <ul className="list-wrap list-none">
                  <li>
                    <a href="https://www.facebook.com/share/spSSDfVvCstAvVS7/?mibextid=qi2Omg">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/invites/contact/?igsh=1ht3x78l61ifb&utm_content=vu9okd4">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/105264915/admin/page-posts/published/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>

      <div className="newsletter-shape-wrap">
        <img
          src="/img/images/newsletter_bg_shape.png"
          alt=""
          className="bg-shape"
        />
        <img
          src="/img/images/newsletter_shape01.png"
          alt=""
          className="shape-one"
        />
        <img
          src="/img/images/newsletter_shape02.png"
          alt=""
          className="shape-two"
        />
        <img
          src="/img/images/newsletter_shape03.png"
          alt=""
          className="shape-three"
        />
        <img
          src="/img/images/newsletter_shape04.png"
          alt=""
          className="shape-four"
        />
        <img
          src="/img/images/newsletter_shape05.png"
          alt=""
          className="shape-five"
        />
        <img
          src="/img/images/newsletter_shape06.png"
          alt=""
          className="shape-six"
        />
      </div>
    </section>
  );
};

export default NewsLetterArea;
