import React from "react";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";

const BlogBreadcrumbArea = () => {
  const parallax = useParallax({
    translateY: [-32, 32],
    translateX: [-32, 32],
    rootMargin: 0,
  });

  return (
    <section className="breadcrumb-area breadcrumb-area-three parallax pt-175 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content">
              <h2 className="title">Blog</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="breadcrumb-shape-wrap-two">
        <div className="parallax-shape" ref={parallax.ref}>
          <img
            src="/img/images/breadcrumb_shape03.png"
            className="layer"
            data-depth="0.5"
            alt="img"
          />
        </div>
      </div>
    </section>
  );
};

export default BlogBreadcrumbArea;
