import React from "react";
import ContactForm from "../Contact/ContactForm";
import InnerContactForm from "../Contact/InnerContactForm";

const ConsultationAreaTwo = () => {
  return (
    <section className="consultation-area consultation-area-two pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="consultation-content">
              <div className="section-title mb-25">
                <span className="sub-title">95% Accuracy</span>
                <h3 className="title">
                  Transformative IT Solutions for the Modern Business
                </h3>
              </div>

              <p>
                KodeKlan offers a dynamic suite of services designed to
                accelerate your digital growth and streamline your operations.
                From custom web and software development to IT consulting and
                digital transformation, we provide end-to-end solutions that
                drive innovation. Our expertise extends to cloud storage and
                management, ensuring your data is secure, scalable, and
                accessible. We also specialize in ideation, creation, and
                DevOps, enabling seamless collaboration between development and
                operations for faster, more efficient delivery. Whether you're
                looking to optimize your existing systems or build something
                entirely new, our comprehensive approach empowers your business
                to thrive in an ever-evolving digital landscape
              </p>

              <div className="consultation-list">
                <ul className="list-wrap">
                  <li>
                    <h6 className="title">12X</h6>
                    <p>
                      Faster Order <span>Processing</span>
                    </p>
                  </li>
                  <li>
                    <h6 className="title">99%</h6>
                    <p>
                      Processing <span>Accuracy</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="inner-contact-form-wrap">
                <InnerContactForm />
              </div>
        </div>
      </div>

      <div className="consultation-shape-wrap">
        <img
          src="/img/images/consultation_shape01.png"
          alt=""
          className="shape-one ribbonRotate"
        />
        <img
          src="/img/images/consultation_shape02.png"
          alt=""
          className="shape-two float-bob-x"
        />
      </div>
    </section>
  );
};

export default ConsultationAreaTwo;
