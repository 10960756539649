import React from "react";
import { Link } from "react-router-dom";

const NewsLetterAreaTwo = () => {
  return (
    <section className="newsletter-area-two mb-4">
      <div className="container">
        <div className="newsletter-wrap">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title title-style-two white-title text-center mb-30">
                <span className="sub-title">Get update</span>
                <h2 className="title">Get latest updates and deals on</h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="newsletter-form">
                <ul className="list-wrap list-none">
                  <li>
                    <a href="https://www.facebook.com/share/spSSDfVvCstAvVS7/?mibextid=qi2Omg">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/invites/contact/?igsh=1ht3x78l61ifb&utm_content=vu9okd4">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/105264915/admin/page-posts/published/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="newsletter-shape-wrap-two">
            <img src="/img/images/h3_newsletter_shape01.png" alt="" />
            <img src="/img/images/h3_newsletter_shape02.png" alt="" />
            <img src="/img/images/h3_newsletter_shape03.png" alt="" />
            <img src="/img/images/h3_newsletter_shape04.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsLetterAreaTwo;
