import React, { useRef, useState } from 'react';
import { sendEmail } from "../../sendEmail";

const EnquiryForm = ({isModalOpen, setIsModalOpen}) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        job_role: "",
        cover_letter: "",
        contact_no: "",
        skills: [],
        newSkill: ""
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        email: "",
        job_role: "",
        cover_letter: "",
        contact_no: "",
        skills: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const newFormRef = useRef();

    const templateID = "template_nd70obj";
    const serviceID = "service_btwidzi";
    const publicKey = "SbXF_YbMRF1Vn6iqM";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };

    const handleAddSkill = () => {
        const { newSkill } = formData;
        if (newSkill && !formData.skills.includes(newSkill)) {
            setFormData(prev => ({
                ...prev,
                skills: [...prev.skills, newSkill],
                newSkill: ""
            }));
        }
    };

    const handleSkillDelete = (skillToDelete) => {
        setFormData(prev => ({
            ...prev,
            skills: prev.skills.filter(skill => skill !== skillToDelete)
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.name) errors.name = "Name is required.";
        if (!formData.email) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email is not valid.";
        }
        if (!formData.job_role) errors.job_role = "Job role is required.";
        if (!formData.cover_letter) {
            errors.cover_letter = "Cover letter is required.";
        } else if (formData.cover_letter.length < 10) {
            errors.cover_letter = "Cover letter should be at least 10 characters long.";
        }
        if (!formData.contact_no) {
            errors.contact_no = "Contact number is required.";
        } else if (!/^\d{10}$/.test(formData.contact_no)) {
            errors.contact_no = "Contact number must be exactly 10 digits.";
        }
        if (formData.skills.length === 0) errors.skills = "At least one skill is required.";

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);
        setFormSubmitted(false);

        try {
            // Check if newFormRef is correctly passed
            console.log(newFormRef.current);  // Debugging: This should log the form element

            // If the form reference is correct, send the email
            const response = await sendEmail(newFormRef.current, serviceID, templateID, publicKey);
            console.log("Email sent successfully:", response);

            // Reset the form after submission
            setFormData({
                name: "",
                email: "",
                job_role: "",
                cover_letter: "",
                contact_no: "",
                skills: [],
                newSkill: ""
            });
            setFormSubmitted(true);
            setIsSubmitting(false);
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error sending email:", error);
            setIsSubmitting(false);
        }
    };

    return (
        <>


            {isModalOpen && (
                <div className="modal" style={{ cursor: 'auto' }}>
                    <div className="modal-content">
                        <span
                            className="close"
                            onClick={() => setIsModalOpen(false)}
                            style={{ cursor: 'pointer' }}
                        >
                            &times;
                        </span>
                        <h3>Inquire About a Job Role</h3>
                        <form onSubmit={handleSubmit} className='enq-form'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {formErrors.name && <small className="text-danger">{formErrors.name}</small>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {formErrors.email && <small className="text-danger">{formErrors.email}</small>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="job_role"
                                    name="job_role"
                                    placeholder='Job Role'
                                    value={formData.job_role}
                                    onChange={handleChange}
                                />
                                {formErrors.job_role && <small className="text-danger">{formErrors.job_role}</small>}
                            </div>
                            <div className="form-group">
                                <textarea
                                    id="cover_letter"
                                    name="cover_letter"
                                    value={formData.cover_letter}
                                    onChange={handleChange}
                                    placeholder='Cover Letter'
                                />
                                {formErrors.cover_letter && <small className="text-danger">{formErrors.cover_letter}</small>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="contact_no"
                                    name="contact_no"
                                    placeholder='Contact No.'
                                    value={formData.contact_no}
                                    onChange={handleChange}
                                />
                                {formErrors.contact_no && <small className="text-danger">{formErrors.contact_no}</small>}
                            </div>
                            <div className="form-group">
                                <div className="skill-input">
                                    <input
                                        type="text"
                                        id="newSkill"
                                        name="newSkill"
                                        value={formData.newSkill}
                                        onChange={handleChange}
                                        placeholder="Enter Skill"
                                        className="form-control"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleAddSkill}
                                        disabled={!formData.newSkill}
                                        className="btn btn-primary"
                                    >
                                        Add Skill
                                    </button>
                                </div>

                                {formErrors.skills && <small className="text-danger">{formErrors.skills}</small>}

                                <div className="skills-tags">
                                    {formData.skills.map((skill, index) => (
                                        <span key={index} className="skill-tag">
                                            {skill} <span className="delete" onClick={() => handleSkillDelete(skill)}>&times;</span>
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <button type="submit" className="btn-submit" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : formSubmitted ? "Submitted" : "Submit Inquiry"}
                            </button>
                        </form>

                        {/* Hidden form */}
                        <form className={'d-none'} ref={newFormRef}>
                            <input type="text" value={formData.name} name='name' />
                            <input type="email" value={formData.email} name='email' />
                            <input type="email" value={formData.job_role} name='job_role' />
                            <input type="text" value={formData.contact_no} name='contact_no' />
                            <input type="text" value={formData.cover_letter} name='cover_letter' />
                            <input type="text" value={formData.skills.join(", ")} name='skills' />
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default EnquiryForm;
