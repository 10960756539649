import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function IdeationCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "100%",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  const imgStyle = {
    // width: '20%',

    height: "100px",
    objectFit: "cover", // Ensure images are resized correctly
    marginBottom: "15px", // Add space below the image
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row g-4 justify-content-center">
            {" "}
            {/* Added g-4 for gap */}
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/ConceptDevelopment.png"
                  alt=""
                  style={imgStyle}
                />
                <div>
                  <h3>Concept Development</h3>
                  <p>
                    We unite nearly to turn original ideas into well- defined
                    generalities. Through brainstorming sessions, we upgrade
                    your vision into practicable plans aligned with your
                    business objects.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/researchandanalysis.png"
                  height="100px"
                  alt=""
                />

                <div>
                  <h3>User Research and Analysis</h3>
                  <p>
                    Understanding your target followership is essential for
                    successful design. We conduct thorough stoner exploration to
                    gather perceptivity into requirements and preferences,
                    informing our design opinions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/WireframingandPrototyping.png"
                  alt=""
                  height={"100px"}
                  style={imgStyle}
                />
                <div>
                  <h3>Wireframing and Prototyping</h3>
                  <p>
                    Our wireframing and prototyping services help you fantasize
                    ideas before development. We produce low- dedication
                    wireframes and interactive prototypes to outline the stoner
                    trip and gather early feedback.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/VisualDesign.png"
                  alt=""
                  style={imgStyle}
                />
                <div>
                  <h3>Visual Design</h3>
                  <p>
                    We create visually compelling designs that reflect your
                    brand identity. Our team develops stunning visuals, ensuring
                    a cohesive aesthetic that enhances user experience.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/UserExperience(UX)Design.png"
                  alt=""
                  style={imgStyle}
                />
                <div>
                  <h3>User Experience (UX) Design</h3>
                  <p>
                    Stoner experience is central to our design gospel. We
                    concentrate on intuitive navigation and usability,
                    conducting testing to validate designs and enhance stoner
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/CollaborationandFeedback.png"
                  alt=""
                  style={imgStyle}
                />
                <div>
                  <h3>Collaboration and Feedback</h3>
                  <p>
                    We value collaboration and laboriously seek your input
                    throughout the design process. This ensures the final design
                    aligns with your vision and objects.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/IdeationDesigning/partnerwithusinnovation.png"
                  alt=""
                  style={imgStyle}
                />
                <div>
                  <h3>Partner with Us for Innovative Solutions</h3>
                  <p>
                    Choosing our creativity and design services means partnering
                    with a platoon devoted to turning your ideas into reality.
                    Let us help you produce results that allure your
                    followership and drive your business forward.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
