import React, { useRef, useState } from "react";
import { sendEmail } from "../../sendEmail";

const InnerContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_no: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();

  // Form validation logic
  const formValidation = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
    if (!formData.contact_no) newErrors.contact_no = "Number is required";
    else if (!/^\d{10}$/.test(formData.contact_no)) newErrors.contact_no = "Phone number must be of 10 digits";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValidation()) {
      return;
    }
    try {
      setIsSubmitting(true);
      const templateID = "template_w4lbzfq";
      const serviceID = "service_btwidzi";
      const publicKey = "SbXF_YbMRF1Vn6iqM";
      const response = await sendEmail(formRef.current, serviceID, templateID, publicKey);
      setFormData({
        name: "",
        email: "",
        contact_no: "",
        subject: "",
        message: "",
      });
      setErrors({});
      console.log("Email sent successfully:", response);
    } catch (error) {
      console.log("Email failed to send:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="form-grp">
        <label htmlFor="name">
          <i className="fas fa-user"></i>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <span className="error text-danger text-small">{errors.name}</span>}
      </div>

      <div className="form-grp">
        <label htmlFor="phone">
          <i className="fas fa-phone phone-icon"></i>
        </label>
        <input
          type="text"
          id="phone"
          name="contact_no"
          placeholder="Phone"
          value={formData.contact_no}
          onChange={handleChange}
        />
        {errors.contact_no && <span className="error text-danger text-small">{errors.contact_no}</span>}
      </div>

      <div className="form-grp">
        <label htmlFor="email">
          <i className="fas fa-envelope"></i>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <span className="error text-danger text-small">{errors.email}</span>}
      </div>

      <div className="form-grp">
        <label htmlFor="subject">
          <i className="fas fa-book-alt"></i>
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Subject"
          value={formData.subject}
          onChange={handleChange}
        />
        {errors.subject && <span className="error text-danger text-small">{errors.subject}</span>}
      </div>

      <div className="form-grp">
        <label htmlFor="comment">
          <i className="fas fa-user-edit"></i>
        </label>
        <textarea
          name="message"
          id="comment"
          placeholder="How can we help you? Feel free to get in touch!"
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        {errors.message && <span className="error text-danger text-small">{errors.message}</span>}
      </div>

      <button type="submit" className="btn" disabled={isSubmitting}>
        {isSubmitting ? "Submitting..." : "Send Message"} <span></span>
      </button>
    </form>
  );
};

export default InnerContactForm;
