import React from "react";
import { Link } from "react-router-dom";
import "./Blogs.css";

const BlogItem = ({ item }) => {
  return (
    <div className="inner-blog-item shadow-sm rounded-lg overflow-hidden">
      <div className="inner-blog-thumb">
        <img
          src={item.src}
          alt={item.title}
          className="w-100 h-60 object-cover rounded-top"
        />
      </div>

      <div className="inner-blog-content p-2  pt-0">
        <div className="blog-meta-two mb-3">
          <ul className="list-wrap blogs-ul d-flex flex-wrap">
            <li className="tag mr-3 blog-tags">
              <Link className="text-white">{item.tag}</Link>
            </li>
            <li className="mr-3">
              <i className="fal fa-clock mr-1" /> {item.reading_time}
            </li>
            <li className="mr-3">
              <i className="fal fa-calendar mr-1" /> {item.created_at}
            </li>
            <li>
              By <Link className="text-secondary">{item.author}</Link>
            </li>
          </ul>
        </div>

        <h2 className="title h4 mb-3">
          <Link className="text-dark hover:text-primary transition">
            {item.title}
          </Link>
        </h2>

        <p className="text-muted mb-4">{item.desc.slice(0, 150)}</p>

        <Link to={`/blogs/${item._id}`} className="read-more-btn text-primary">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogItem;
