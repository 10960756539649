import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
// import card1 from '../../../../public/img/CloudManageCard/proactivemonitoring.png'

export default function ServicesCards({ cardDetails }) {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "350px",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            {cardDetails.map((item) => (
              <>
                <div
                  className="col-sm-4 col-xs-12 aos-init"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <div
                    className="color1"
                    style={cardStyle}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.boxShadow =
                        cardHoverStyle.boxShadow)
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                    }
                  >
                    <img src={item.img} height="100px" alt="" />

                    <div className="">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
