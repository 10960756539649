import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ServiceBanner.css';

const ServiceBanner = () => {
  return (
    <div className="services-container">
      <div className="container">
        <div className="text-center services-header">
          <h1 className="main-heading">Startup Projects: Transforming Ideas into Reality</h1>
          <p className="subtitle">
          At KodeKlan, we are passionate about turning startup ideas into fully functional digital solutions. From web and app development to custom CRM systems, we provide end-to-end technology solutions tailored to meet the unique needs of growing businesses. Here’s a closer look at some of our successful projects:
          </p>
        </div>
        
        <div className="row g-4 mt-4">
         
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>GlobleCrafts – A Global E-Commerce Platform <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              We developed a feature-rich e-commerce website for GlobleCrafts, enabling seamless shopping experiences for customers worldwide. With a modern UI/UX, secure payment gateway integration, and a user-friendly dashboard, the platform provides effortless browsing, ordering, and checkout experiences.
              </p>
            </div>
          </div>
          
        
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Ewire Softtech – Website & CRM Development <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              For Ewire Softtech, we created a dynamic website showcasing their IT services, along with a custom CRM system to streamline client interactions, automate workflows, and enhance business efficiency. The CRM allows for seamless data management, real-time updates, and secure handling of customer records.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Footsy – App & Website for Online Footwear Preorders <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              Footsy is a unique footwear platform that required both a mobile app and a website to handle preorders efficiently. We built an intuitive and visually appealing e-commerce platform integrated with secure payment solutions, order tracking, and personalized recommendations, ensuring a seamless shopping experience for customers.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Journey Monk – Travel Booking Website <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              Journey Monk, our own travel venture, needed a fully functional travel booking platform to offer customized travel packages. We designed a high-performance website with real-time package listings, seamless booking features, secure payment processing, and a user-friendly interface, making travel planning easier for customers.
              </p>
            </div>
          </div>
          
         
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>BQA Reviews – Government Review & Evaluation Platform <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              For BQA (Bahrain Quality Assurance), we built a secure and scalable government review platform, allowing streamlined evaluations and transparent reporting. The platform ensures data security, compliance with regulatory standards, and an intuitive dashboard for efficient management.
              </p>
            </div>
          </div>
          
          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>LuvuPlush – A Dynamic Website for Plush Toy Brand <span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              LuvuPlush required a custom-built dynamic website to showcase their premium plush toy collection. We developed an engaging, visually rich website that highlights their brand identity while offering easy navigation, high-speed performance, and a seamless shopping experience.
              </p>
            </div>
          </div>

         
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Lavish Look – A Stylish Beauty & Fashion Website<span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              For Lavish Look, we created a sleek and stylish website tailored for the beauty and fashion industry. The platform includes aesthetic design elements, responsive layouts, product galleries, and seamless e-commerce functionality, making it an ideal space for fashion lovers to explore and shop.
              </p>
            </div>
          </div>

          
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-title">
                <h2>Green Farm (Australia) – POS & E-Commerce Solution for a Restaurant<span className="arrow">↗</span></h2>
              </div>
              <p className="service-description">
              We partnered with Green Farm, an Australian restaurant, to develop a custom POS system and an e-commerce website. The POS system simplifies order management, billing, and customer tracking, while the e-commerce platform enables online ordering, menu customization, and seamless checkout, enhancing overall efficiency for the restaurant.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;