import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { jobListings } from '../../jobs';
import Layout from '../../layouts/Layout';
import BreadcrumbArea from '../../components/BreadcrumbArea/BreadcrumbArea';
import EnquiryForm from '../../components/Careers/EnquiryForm';

const JobDescription = () => {
  // Extract jobId from the URL
  const { jobId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Find the job by the jobId
  const job = jobListings.find((job) => job._id === jobId);

  // If job not found, display a message
  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <Layout header={1} footer={1} className="" mainClassName="">
      <BreadcrumbArea
        title="Careers"
        subtitle={"Job Description"}
        className={"pt-175 pb-4"}
      />
      <div className="job-description container">
        <div className="description-header">
          <h2>{job.jobTitle}</h2>
          <p className="company-name">{job.company}</p>
          <p className="location">{job.city}</p>
          <p className="job-type">{job.jobType}</p>
          <p className="experience-level">{job.experienceLevel}</p>
        </div>
        <div className="description-body">
          <p className="job-description-text">{job.description}</p>
          <p><strong>About the Company:</strong> {job.aboutCompany}</p>
          <h4 className="section-title">Key Responsibilities:</h4>
          <ul className="responsibilities-list">
            {job.keyResponsibilities.map((item, idx) => (
              <li key={idx} className="list-item">{item}</li>
            ))}
          </ul>
          <h4 className="section-title">Required Skills:</h4>
          <ul className="skills-list">
            {job.requiredSkills.map((item, idx) => (
              <li key={idx} className="list-item">{item}</li>
            ))}
          </ul>
          <h4 className="section-title">Preferred Qualifications:</h4>
          <ul className="qualifications-list">
            {job.preferredQualifications.map((item, idx) => (
              <li key={idx} className="list-item">{item}</li>
            ))}
          </ul>
          <button className='apply-btn' onClick={()=> setIsModalOpen(true)}>Apply Now</button>
        </div>
        <div>
          <EnquiryForm
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen} />
        </div>
      </div>
    </Layout>
  );
};

export default JobDescription;
