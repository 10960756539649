import React from "react";
import BlogItem from "./BlogItem";
import BlogPagination from "./BlogPagination";
import BlogSidebar from "./BlogSidebar/BlogSidebar";
import { Link } from "react-router-dom";

const InnerBlogArea = () => {
  const blog_items = [
    {
      _id: "1",
      title:
        "10 Crucial Trends Shaping Web Development in 2025: A Fresh Perspective",
      tag: "Web Development",
      reading_time: "10 min read",
      created_at: "February 7, 2025",
      author: "Mayank Kumar Rajoria",
      src: "img/blog/blog2.jpg",
      desc: "Web development is continuously evolving, with new technologies and approaches reconsidering the way digital gestures are created. As we step into 2025, emerging trends are anticipated to reshape the industry in ways that go beyond conventional predictions. This article presents a fresh perspective on the ten crucial trends that will impact web development in the coming year. Whether you're an inventor, entrepreneur, or tech enthusiast, staying ahead of these trends will ensure that your systems remain relevant and innovative...",
    },
  ];

  return (
    <section className="inner-blog-area pb-120">
      <div className="container">
        <div className="row justify-content-start">
          {/* Map through blog items and display in a 3-column grid */}
          {blog_items.map((item) => (
            <div key={item._id} className="col-lg-4 col-md-6 mb-4">
              <Link to={`/blogs/${item._id}`}>
                <BlogItem item={item} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InnerBlogArea;
