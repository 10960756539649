import React, { useState, useEffect } from 'react';
import './Header.css'; 
import { Link } from 'react-router-dom';

const Header = () => {
  
  const [developers, setDevelopers] = useState(0);
  const [experience, setExperience] = useState(0);
  const [retention, setRetention] = useState(0);
  const [refund, setRefund] = useState(0);
  
  
  useEffect(() => {
    const animateValue = (setter, start, end, duration) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setter(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    
    animateValue(setDevelopers, 0, 10, 2000);
    animateValue(setExperience, 0, 9, 1500);
    animateValue(setRetention, 0, 10, 2500);
    animateValue(setRefund, 0, 20, 1800);
  }, []);

  return (
    <section className="hero-section ">
      <div className="container pt-10 mt-4 ">
        <div className="row align-items-center">
          {/* Left Content */}
          <div className="col-lg-6 mb-5 mb-lg-0">
            <h1 className="fw-bold mb-4">Empowering Startups with End-to-End Tech Solutions</h1>
            <p className="mb-4">
            <span className="fw-bold">Have a game-changing business idea but unsure how to bring it to life?</span>  
            At KodeKlan, we turn your vision into reality. 
            <p className="mt-3">
               We specialize in providing comprehensive technical support for startups looking to establish or expand their online presence. Whether you're launching a new venture or scaling your existing business, our expert team is here to guide you every step of the way.
</p>
            </p>
            <p>
            <span className='fw-bold'>Your Idea, Our Innovation –</span> We listen, strategize, and develop tailored solutions that align with your business goals.<br/>
            <span className='fw-bold'>Funding Support –</span>
              Lack of capital? We offer financial backing on an equity basis, ensuring your startup gets the boost it needs.<br/>
             <span className='fw-bold'>One-Stop Solution –</span> From software and website development to cloud solutions and IT consulting, we provide everything under one roof.
            </p>
            <p>
            With KodeKlan, your startup isn’t just another project—it’s a vision we believe in. Let's build something extraordinary together.
            </p>
        
            <Link to='/contact'>
            <div className="button-wrapper">
              <button className=" share-button ">
                <span>Get in Touch Today & Kickstart Your Startup Success</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right ms-2" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
              </button>
            </div>
            </Link>
            
            
            <div className="row stats-container mt-5">
              
              <div className="col-6 col-md-3 stat-item mb-4 mb-md-0">
                <h3 className="fw-bold">{developers}+</h3>
                <p className="stat-text">Project<br />Finished</p>
              </div>
              
              
              <div className="col-6 col-md-3 stat-item mb-4 mb-md-0">
                <h3 className="fw-bold">{experience}+</h3>
                <p className="stat-text">Satisfied<br />Customer</p>
              </div>
              
              
              <div className="col-6 col-md-3 stat-item">
                <h3 className="fw-bold">{retention}+</h3>
                <p className="stat-text">Industries</p>
              </div>
              
              
              <div className="col-6 col-md-3 stat-item">
                <h3 className="fw-bold">{refund}+</h3>
                <p className="stat-text">Our<br/>Employees</p>
              </div>
            </div>
          </div>
          
          
          <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <div className="visualization-container">
             
              <div className="ui-card">
                
                <div className="browser-dots">
                  <div className="browser-dot green"></div>
                  <div className="browser-dot yellow"></div>
                  <div className="browser-dot red"></div>
                </div>
                
                
                <div className="mobile-phone">
                  
                  <div className="phone-screen">
                    <div className="message-line"></div>
                    <div className="message-line-short"></div>
                    <div className="reply-bubble"></div>
                  </div>
                  
                 
                  <div className="phone-apps">
                    <div className="app-icon pink"></div>
                    <div className="app-icon purple"></div>
                    <div className="app-icon yellow"></div>
                    <div className="app-icon green"></div>
                    <div className="app-icon blue"></div>
                    <div className="app-icon pink"></div>
                  </div>
                </div>
                
                
                <div className="pie-chart">
                  <div className="pie-segment blue"></div>
                  <div className="pie-segment red"></div>
                  <div className="pie-segment yellow"></div>
                  <div className="pie-segment green"></div>
                  <div className="pie-center"></div>
                </div>
                
                
                <div className="bar-charts">
                  <div className="bar bar-red"></div>
                  <div className="bar bar-blue"></div>
                  <div className="bar bar-green"></div>
                  <div className="bar bar-yellow"></div>
                </div>
                
                
                <div className="gears">
                  <div className="gear large-gear">
                    <div className="gear-center"></div>
                    <div className="gear-tooth top"></div>
                    <div className="gear-tooth bottom"></div>
                    <div className="gear-tooth left"></div>
                    <div className="gear-tooth right"></div>
                  </div>
                  <div className="gear small-gear"></div>
                </div>
                
               
                <div className="data-lines">
                  <div className="data-line yellow-line"></div>
                  <div className="data-line blue-line"></div>
                  <div className="data-line green-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;