import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import ServicesCards from "../../../components/ServicePages/ServicesCards";

export default function CloudCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardDetails = [
    {
      title: "Proactive Monitoring",
      description:
        "We provide proactive monitoring to continuously observe your cloud infrastructure for performance issues and threats. Advanced tools help us detect anomalies and respond swiftly, minimizing downtime.",
      img: "/img/CloudManageCard/proactivemonitoring.png",
    },
    {
      title: "Performance Optimization",
      description:
        "Our team specializes in optimizing cloud performance through regular assessments. We identify bottlenecks and implement enhancements to ensure your applications meet business demands effectively.",
      img: "/img/CloudManageCard/performanceoptimization.png",
    },
    {
      title: "Security Management",
      description:
        " Protecting your cloud data is our top priority. We offer continuous threat detection, vulnerability assessments, and compliance checks to safeguard your assets from cyber threats.",
      img: "/img/CloudManageCard/securityManagement.png",
    },
    {
      title: "Cost Management",
      description:
        "Gaining control over pall spending with our cost operation services. We dissect operation patterns to optimize resource allocation, reducing gratuitous charges while meeting your business requirements.",
      img: "/img/CloudManageCard/CostManagement.png",
    },
    {
      title: "Backup and Disaster Recovery",
      description:
        "Our backup and disaster recovery results insure data security and easy recovery during dislocations. We apply robust strategies to maintain business durability and cover against data loss.",
      img: "/img/CloudManageCard/BackupAndDisasterRecovery.png",
    },
    {
      title: "Cloud Governance",
      description:
        "Effective Pall governance is essential for compliance and effectiveness. We help produce fabrics and programs that promote responsibility and insure stylish practices across your pall terrain.",
      img: "/img/CloudManageCard/cloudGoverence.png",
    },
    {
      title: "Partner with Us for Cloud Excellence",
      description:
        "Choosing our Cloud Managed Services means gaining a dedicated partner focused on optimizing your cloud strategy. Let us enhance your cloud environment while you focus on innovation and growth.",
      img: "/img/CloudManageCard/PartnerWithUsForCloudExcellence.png",
    },
  ];
  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "100%",
  };

  return <ServicesCards cardDetails={cardDetails} />;
}
